
.typed-element {
  display: flex;
  align-items: center;
}
.typed-element .typed-cursor {
    opacity: 1;
    animation: typedjsBlink 0.7s infinite;
}
@keyframes typedjsBlink {
50% {
    opacity: 0.0;
}
}
